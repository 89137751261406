import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./letter.css"

const Letter = () => {
  const data = useStaticQuery(graphql`
    query {
      larissa: file(relativePath: { eq: "larissa.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout title="Letter">
      <SEO title="Letter" />
      <div className="flex-grid mobile-wrap">
        <div style={{ flex: "1 1 215px" }} className="poster-left only-desktop" />
        <div className="letter-big poster-left">
          <div className="vertical-center">
            Being a model isn’t always easy—not many people really understand
            who we are or what our lives are like. Young models arrive in New
            York with visions of stardom as a professional model, but the
            reality they face as they embark on their career is far less
            glamorous. As the media has extensively documented, young models
            deal with a variety of problems, from{" "}
            <a
              href="https://money.cnn.com/2016/05/11/news/runway-injustice-model-apartments/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              cramped model apartments
            </a>{" "}
            to{" "}
            <a
              href="https://www.nytimes.com/2014/09/21/opinion/sunday/who-runs-the-girls.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              unsavory promoters
            </a>{" "}
            to{" "}
            <a
              href="https://money.cnn.com/2016/05/09/news/runway-injustice-model-expenses/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              late payments
            </a>{" "}
            to{" "}
            <a
              href="https://www.nytimes.com/2017/10/23/fashion/sexual-harassment-law-models-new-york-state-harvey-weinstein.html?action=click&contentCollection=fashion&region=rank&module=package&version=highlights&contentPlacement=1&pgtype=sectionfront&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              unsafe working conditions
            </a>
            . And in a city where they just arrived with no family or friends,
            they largely deal with these problems alone.
          </div>
        </div>
        <div style={{ flex: "1" }} className="poster-left" />
        <div style={{ flex: "0 0 450px", position: "relative" }}>
          <Img fluid={data.larissa.childImageSharp.fluid} alt="Home" />
          <div className="letter-image-info">
            Larissa Drekonja - Founder, Model
          </div>
        </div>
      </div>
      <div className="flex-grid">
        <div className="only-desktop" style={{ flex: "1 1 215px" }} />
        <div className="letter-big letter-small" style={{ flex: "0 1 640px" }}>
          <p>
            In pondering this situation, I realized the modeling industry needed
            a safer and larger network. For in spite of all these struggles,
            when I think about the amazing models I’ve met throughout my career,
            I tend to reflect on the wonderful qualities that have united us. We
            are bright and talented, passionate about the arts and motivated to
            contribute positively to the world. It all got me wondering: is
            there a way to unify us with one voice? Could there be a platform to
            connect our community through common interests and experiences? As
            the wheels were turning...Neon Coat was born!
          </p>
          <p>
            Neon Coat is a groundbreaking new app made just for us! NC offers a
            whole new world of complimentary first-class experiences, free
            premium merchandise, and inspiring volunteer opportunities—right at
            your fingertips! High-end restaurants, nights out with friends,
            luxury beauty treatments, uplifting charitable events, and so much
            more with our safe and trusted network of partners. Neon Coat stands
            for having fun, enjoying life, and creating a community to promote
            the common good.
          </p>
          <p>
            We encourage you to invite your model friends to strengthen the
            community and enjoy the offers together. Choose what you love on NC,
            experience it, share it—inform the world about the things you love.
            <br/>
            <br/>
            <br/>
          </p>
        </div>
        <div className="only-desktop" style={{ flex: "1" }} />
        <div className="only-desktop" style={{ flex: "0 0 450px" }}></div>
      </div>
    </Layout>
  )
}

export default Letter
